<template>
    <div class="placeholder-card card bg-light d-flex justify-content-center align-items-center h-100">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "PlaceholderPanel",
        data () {
            return {

            };
        },
        computed: {

        },
        methods: {

        },
    }
</script>

<style lang="scss" scoped>
    .placeholder-card {
        border-radius: 15px;
    }
</style>
